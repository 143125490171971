<template>
  <div class="container">
    <div class="banner"><img :src="bannerUrl" class="w100 block" /></div>

    <div class="bgtheme px10 execllentProjectWrap">
      <section
        class="mt20 clump"
        @click="
          $router.push({
            path: '/prefecture/download',
            query: { isUserPad: true },
          })
        "
        :style="{ backgroundImage: `url(${clumpBgImg})` }"
        v-for="(unit, index) in execllentProject"
        :key="index"
      >
        <div v-if="index % 2 == 1" class="relative row">
          <aside class="absolute cf tc ratetips">
            <span class="fb mr5">·</span>满{{ baseInfo.fullAmount / 100 }}减{{
              baseInfo.cutAmount / 100
            }}<span class="ml5 fb">·</span>
          </aside>
          <aside class="w51 px10">
            <div class="font24 mt10 tr grayblue">
              {{
                unit.projectName.length > 14
                  ? unit.projectName.substring(0, 14) + "..."
                  : unit.projectName
              }}
            </div>
            <div class="tr grayblue">
              <span>服务时长</span>
              <span>{{ unit.times }}</span>
              <span>分钟</span>
            </div>
            <div class="between my10">
              <section></section>
              <section class="row">
                <section>
                  <aside class="px5 bgdarkblue cf fb">#满减价#</aside>
                  <aside class="font12 linethrouth darkblue">
                    市场价:￥{{ unit.price / 100 }}
                  </aside>
                </section>
                <section class="fb darkblue">
                  ￥<span class="font32">{{ unit.activityPrice / 100 }}</span>
                </section>
              </section>
            </div>
            <div class="tr cf">
              <span class="bgdarkblue morebtn">查看详情</span>
            </div>
          </aside>
          <aside class="w49">
            <img
              :src="unit.photoPath"
              class="w100 block titleimg"
              style="height:180px"
            />
          </aside>
        </div>
        <div v-else class="relative row">
          <aside class="absolute cf tc ratetips">
            <span class="fb mr5">·</span>满{{ baseInfo.fullAmount / 100 }}减{{
              baseInfo.cutAmount / 100
            }}<span class="ml5 fb">·</span>
          </aside>
          <aside class="w49">
            <img
              :src="unit.photoPath"
              class="w100 block titleimg"
              style="height:180px"
            />
          </aside>
          <aside class="w51 px10">
            <div class="font24 mt10 tr grayblue">
              {{
                unit.projectName.length > 14
                  ? unit.projectName.substring(0, 14) + "..."
                  : unit.projectName
              }}
            </div>
            <div class="tr grayblue">
              <span>服务时长</span>
              <span>{{ unit.times }}</span>
              <span>分钟</span>
            </div>
            <div class="between my10">
              <section></section>
              <section class="row">
                <section>
                  <aside class="px5 bgdarkblue cf fb">#满减价#</aside>
                  <aside class="font12 linethrouth darkblue">
                    市场价:￥{{ unit.price / 100 }}
                  </aside>
                </section>
                <section class="fb darkblue">
                  ￥<span class="font32">{{ unit.activityPrice / 100 }}</span>
                </section>
              </section>
            </div>
            <div class="tr cf">
              <span class="bgdarkblue morebtn">查看详情</span>
            </div>
          </aside>
        </div>
      </section>
    </div>

    <van-tabs @click="changeTab">
      <van-tab
        v-for="(unit, index) in cateLogList"
        :key="index"
        :title="unit.catalogFirstName"
      >
        <div class="px10">
          <div v-if="list.length">
            <section
              class="mt10 p10 lump"
              @click="$router.push({ path: '/prefecture/download' })"
              v-for="(inner, innerdex) in list"
              :key="'list' + innerdex"
            >
              <div class="row">
                <img :src="inner.photoPath" alt="" class="iconImg" />
                <aside class="flex1 ml10">
                  <div class="between">
                    <span class="fb" style="color:#3e2424">{{
                      baseInfo.activityName
                    }}</span>
                    <span class="font12" v-if="inner.isAllow">
                      <span class="appoint-left">最近可约</span>
                      <span class="appoint-right">{{
                        inner.recentlyTime
                      }}</span>
                    </span>
                    <span v-else></span>
                  </div>
                  <div class="c9 font12 mt5">
                    <span class="relative top1">
                      <van-rate
                        :value="1"
                        count="1"
                        size="14"
                        disabled-color="rgba(255, 140, 66, 1)"
                        disabled
                      />
                    </span>
                    <span class="ml3"
                      >{{ Number(inner.score).toFixed(1) }}分</span
                    >
                    <span class="mx5 line">|</span>
                    <span>
                      <i
                        class="iconfont icon-time"
                        style="font-size:12px;color:#3e2424"
                      ></i>
                      {{ inner.times }}分钟
                    </span>
                    <span class="mx5 line">|</span>
                    <span>
                      已售<span class="orange">{{
                        inner.orderNum > 10000 ? "1w+" : inner.orderNum
                      }}</span>
                    </span>
                  </div>
                  <div class="mt10">
                    <span>
                      {{
                        geoFlag
                          ? `${inner.distance}米${(inner.diffTime / 60).toFixed(
                              0
                            )}分钟`
                          : inner.districtName
                      }}
                    </span>
                  </div>
                </aside>
              </div>
              <div class="relative row mt10 pt5 price-lump bt">
                <span>
                  满{{ (baseInfo.fullAmount || 0) / 100 }}减{{
                    (baseInfo.cutAmount || 0) / 100
                  }}</span
                >
                <div
                  class="absolute priceBgLump"
                  :style="{ backgroundImage: `url(${priceBgImg})` }"
                >
                  <div style="opacity:.8">
                    <span>原价</span>
                    <span class="linethrouth">{{
                      (inner.price / 100).toFixed(2)
                    }}</span>
                  </div>
                  <div class="font18 fb">
                    ￥{{ (inner.activityPrice / 100).toFixed(2) }}
                  </div>
                </div>
              </div>
            </section>
            <section class="py5 tc">
              <!-- 已折叠121个对您无法服务的技师> -->
            </section>
          </div>
          <div v-else>
            <section class="tc py20 c9">暂无数据</section>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
const clumpBgImg = require("@/assets/img/icon969.png");
const priceBgImg = require("@/assets/img/icon968.png");
export default {
  name: "recommend",
  components: {},
  data() {
    return {
      activityId: this.$route.query.activityId,
      clumpBgImg,
      priceBgImg,
      bannerUrl: "",
      baseInfo: {},
      list: [],
      execllentProject: [],
      cateLogList: [],
      geoFlag: false,
      lat: "",
      lon: "",
    };
  },
  methods: {
    // 改变tab
    changeTab(index, title) {
      this.getList({
        activityId: this.activityId,
        catalogFirstId: this.cateLogList[index].catalogFirstId,
      });
    },
    // 获取基本信息
    getBaseInfo() {
      let params = {
        activityId: this.activityId,
      };
      if (this.geoFlag) {
        params = Object.assign(params, {
          lat: this.lat,
          lon: this.lon,
        });
      }
      this.$axios({
        url: this.$requests.member.getFullSubtractBaseInfo,
        method: "post",
        data: params,
      }).then((res) => {
        let wrapper = (res.data && res.data.data) || {};
        this.cateLogList = wrapper.zoneCatalogList || [];
        this.bannerUrl = wrapper.photoPath;
        this.execllentProject = wrapper.goodProjectList || [];
        this.baseInfo = wrapper;
        // 获取列表
        this.getList({
          activityId: this.activityId,
          catalogFirstId: this.cateLogList[0].catalogFirstId,
        });
      });
    },
    // 获取列表
    getList(params) {
      if (this.geoFlag) {
        params = Object.assign(params, {
          lat: this.lat,
          lon: this.lon,
        });
      }
      this.$axios({
        url: this.$requests.member.getFullSubtractList,
        method: "post",
        data: params,
      }).then((res) => {
        let wrapper = res.data || {};
        this.list = wrapper.data || [];
      });
    },
    toNumber(val) {
      return Number(val);
    },
  },
  mounted() {
    // 获取基本信息
    this.getBaseInfo();

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (curPos) => {
          // 成功
          this.geoFlag = true;
          this.lat = curPos.coords.latitude;
          this.lon = curPos.coords.longitude;
        },
        (err) => {
          this.geoFlag = false;
          this.$dialog.alert({
            title: "错误提示",
            message: "地理位置获取失败",
          });
        }
      );
    } else {
      this.$dialog.alert({
        title: "错误提示",
        message: "您的浏览器不支持地理位置",
      });
    }
  },
};
</script>

<style lang="less" scoped>
@theme: rgba(86, 102, 255, 1);
.theme {
  color: @theme;
}
.grayblue {
  color: rgba(27, 55, 107, 1);
}
.darkblue {
  color: rgba(16, 74, 189, 1);
}
.bgdarkblue {
  background: rgba(16, 74, 189, 1);
}
// 优选项目
.execllentProjectWrap {
  .clump {
    background-size: 100% 90%;
    background-repeat: no-repeat;
    .ratetips {
      bottom: 0;
      width: 30%;
      background: rgba(232, 82, 77, 1);
    }
    &:nth-child(2n + 1) {
      .ratetips {
        left: 0;
        border-radius: 0 5px 0 5px;
      }
    }
    &:nth-child(2n) {
      .ratetips {
        right: 0;
        border-radius: 5px 0 5px 0;
      }
    }
  }
  .titleimg {
    border-radius: 5px;
  }
  .morebtn {
    padding: 2px 10px;
    border-radius: 10px;
  }
}
.bgtheme {
  background: @theme;
}
.container {
  height: 100%;
  background: @theme;
}
.linethrouth {
  text-decoration: line-through;
}
.rateBadge {
  padding: 0px 6px;
  border: 1px solid #fff;
  border-radius: 6px;
}
.lump {
  border-radius: 5px;
  padding: 10px;
  background: #fff;
}
.iconImg {
  width: 80px;
  height: 80px;
}
.price-lump {
  padding-left: 20%;
  height: 24px;
  line-height: 24px;
  color: rgba(58, 134, 248, 1);
  .priceBgLump {
    width: 40%;
    right: 0;
    top: -20px;
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 2;
    text-align: center;
    color: #fff;
  }
}
.tips {
  margin-right: 5px;
  padding: 2px 10px;
  color: #fff;
  border-radius: 3px;
  background: @theme;
}
.appoint {
  &-left {
    padding: 2px 5px;
    color: #fff;
    background: rgba(62, 36, 36, 1);
    border: 1px solid rgba(62, 36, 36, 1);
    border-radius: 5px 0 0 0;
  }
  &-right {
    padding: 2px 5px;
    color: rgba(62, 36, 36, 1);
    border: 1px solid rgba(62, 36, 36, 1);
    border-radius: 0 0 5px 0;
  }
}
.line {
  color: rgba(204, 204, 204, 0.5);
}
// 修改vant样式
/deep/.van-tabs {
  background: @theme;
  .van-tabs__nav {
    background: transparent;
  }
  .van-tab {
    padding: 0 5px;
    span {
      display: block;
      padding: 0 10px;
      height: 36px;
      line-height: 36px;
      border-radius: 5px;
      color: #fff;
    }
  }
  .van-tab--active {
    span {
      color: rgba(255, 211, 124, 1);
      // background: linear-gradient(
      //   to bottom,
      //   rgba(211, 79, 243, 1),
      //   rgba(234, 133, 223, 1)
      // );
    }
  }
  .van-tabs__line {
    display: none;
  }
}
</style>
